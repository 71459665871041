//
// Typography
// --------------------------------------------------
.blockquote-sm {
  padding: 3px 15px;
  font-size: $font-size-base;
}

.blockquote {
  font-size: $font-size-lg;

  &.text-right {
    padding-right: $spacer;
    border-right: 0.25rem solid $gray-200;
  }
}

.close {
  opacity: 0.2;
}

.text-light {
  color: inherit !important;
}


//
// Headings
// -------------------------
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {

  small,
  .small {
    font-weight: $headings-font-weight;
    font-size: 75%;
    color: $text-muted;
  }
}

h1, .h1 {
  font-size: $font-h1-size;
  line-height: $font-h1-lineheight;
  font-weight: $font-weight-bold;
}

h2, .h2 {
  font-size: $font-h2-size;
  line-height: $font-h2-lineheight;
  font-weight: $font-weight-bold;
}

h3, .h3 {
  font-size: $font-h3-size;
  line-height: $font-h3-lineheight;
  font-weight: $font-weight-bold;
}

h4, .h4 {
  font-size: $font-h4-size;
  line-height: $font-h4-lineheight;
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-top: 10px;
}


//
// cards
// --------------------------------------------------
.card {
  margin-bottom: $spacer;
  background-color: $card-bg;
  border: none;
}


accordion-group .card-title a.accordion-toggle i {
  transform: rotate(180deg);
  transition: transform 0.35s ease;
}

accordion-group.card-open .card-title a.accordion-toggle i {
  transform: rotate(0deg);
  transition: transform 0.35s ease;
}

.card-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: $font-size-base;
  color: inherit;

  >a,
  >small,
  >.small,
  >small>a,
  >.small>a {
    color: inherit;
    display: block;
  }

  >a:focus {
    outline: none;
  }
}

small,
.small {
  font-size: 85%;
}

.card-group {
  margin-bottom: $spacer;

  // Tighten up margin so it's only between cards
  .card {
    margin-bottom: 0;
    border-radius: $border-radius;
  }

  accordion-group.card-open .card-title a {

    &,
    & i {
      opacity: 1;
    }
  }

  accordion-group .card-title a {

    &,
    & i {
      opacity: 0.6;
    }

    &:hover,
    &:hover i {
      opacity: 1;
    }
  }

  accordion-group+accordion-group {
    .card {
      margin-top: 5px;
    }
  }

  .card-header {
    border-bottom: 0;

    +.card-collapse>.card-body,
    +.card-collapse>.list-group {
      border-top: 1px solid $list-group-border-color;
    }

  }
}


//
// Forms
// --------------------------------------------------
.form-control {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-normal;
  padding: $padding-base-vertical $padding-base-horizontal;
  @include box-shadow(none);

  &:focus {
    @include box-shadow(0 0 5px rgba(81, 203, 238, 1));
    border: 1px solid rgba(81, 203, 238, 1);
  }
}

.input-group-text,
.input-group-addon,
.input-group-append>.btn,
.input-group-prepend>.btn {
  padding-top: $padding-base-vertical;
  padding-bottom: $padding-base-vertical;
}

.form-control-lg {
  padding: $padding-large-vertical $padding-large-horizontal;
}

.form-control-sm {
  padding: $padding-small-vertical $padding-small-horizontal;
}

//
// Deprecated class is used by ng2-datetime and bootstrap-colorpicker
//
.input-group-addon {
  @extend .input-group-text;
}

.form-control+.input-group-addon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group.bootstrap-select>.btn {
  padding-top: $padding-base-vertical;
  padding-bottom: $padding-base-vertical;
}

label {
  font-weight: $font-weight-normal;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: $spacer;
  line-height: inherit;
  color: $legend-color;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  font-size: 15px;
  font-weight: $font-weight-normal;
}

.form-group {
  margin-bottom: $spacer;

  .input-group-text {
    line-height: inherit;
  }
}

.input-group-btn {
  flex-direction: row;
}

.input-group-addon {
  &.bg-primary {
    color: #ecfaec;
    border-color: darken($primary, 10%);
  }

  &.bg-success {
    border-color: darken($success-light, 10%);
  }

  &.bg-info {
    border-color: darken($info-light, 10%);
  }

  &.bg-warning {
    border-color: darken($warning-light, 10%);
  }

  &.bg-danger {
    border-color: darken($danger-light, 10%);
  }
}

.form-horizontal.form-label-left {
  @include media-breakpoint-up(md) {
    .control-label {
      font-weight: $font-weight-bold;
      text-align: left;
    }
  }
}

[data-toggle="buttons"]>.btn input[type="radio"],
[data-toggle="buttons"]>.btn input[type="checkbox"],
[data-toggle="buttons"]>.btn-group>.btn input[type="radio"],
[data-toggle="buttons"]>.btn-group>.btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.form-inline .input-group-text {
  display: table-cell;
}


//
// Feedback states
// --------------------------------------------------
.has-success {
  @include form-control-validation($success);
}

.has-warning {
  @include form-control-validation($warning);
}

.has-error {
  @include form-control-validation($danger);
}

.has-success,
.has-warning,
.has-error {
  .form-control {
    &:focus {
      @include box-shadow(none);
    }
  }
}

.input-rounded {
  border-radius: $border-radius;
}

.form-actions {
  margin: $spacer 0 0;
  padding: $spacer;
  background-color: #f5f5f5;


}


//
// Tables
// --------------------------------------------------
.table td>.abc-checkbox,
.table th>.abc-checkbox {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: -16px;
  padding-left: 20px;
}

.table>thead>tr>th {
  border-top: none;
  border-bottom: none;
}

.table>thead>tr>th {
  font-weight: $font-weight-semi-bold;
  border-bottom-width: 1px;
}

.table-bordered th {
  background-color: $gray-200;
}

.table-lg {

  >thead,
  >tbody,
  >tfoot {
    >tr {

      >th,
      >td {
        padding: 10px;
      }
    }
  }
}

.table-sm {

  >thead,
  >tbody,
  >tfoot {
    >tr {

      >th,
      >td {
        padding: 6px;
      }
    }
  }
}

.table-hover>tbody>tr:hover .progress {
  background-color: $white;
}

.table th,
.table td {
  padding: 0.55rem;
}


//
// Dropdown menus
// --------------------------------------------------
.dropdown-toggle {
  &:after {
    content: none;
  }
}

.dropdown-menu {
  font-size: $font-size-mini;

  &>li>a {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  >.card {
    margin-top: -5px;
    margin-bottom: -5px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9; // IE8
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}


//
// Navs
// --------------------------------------------------
.nav-item .nav-link {
  position: relative;
  cursor: pointer;
}

.nav-pills .nav-link {
  border-radius: 0;
}

.nav-pills .nav-item+.nav-item {
  margin-left: 0;
}

.nav-tabs {
  & .nav-item+.nav-item {
    margin-left: 0
  }

  border-bottom: none;
  background-color: $gray-light;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;

  >.nav-item {
    margin-bottom: -2px;

    >.nav-link {
      padding: 12px 18px;
      border: none;
      color: $text-muted;

      .label {
        margin-bottom: -2px;
      }

      @include transition(color .15s ease-in-out);

      &:hover {
        background-color: transparent;
        color: $text-color;
      }
    }

    .nav-link.open {

      &,
      &:hover,
      &:focus {
        background-color: $nav-tabs-active-link-hover-bg;
        color: $nav-tabs-active-link-hover-color;
      }
    }

    .nav-link.active {

      &,
      &:hover,
      &:focus {
        background-color: $nav-tabs-active-link-hover-bg;
        color: $nav-tabs-active-link-hover-color;
        border: none;
        @include box-shadow(1px 1px 2px #ccc);
      }
    }
  }
}

.tab-content {
  position: relative;
  z-index: 1;
  background-color: $white;

  >.tab-pane {
    padding: $spacer*2 0rem;
  }
}


//
// Navbars
// --------------------------------------------------
.navbar {
  border: none;
  flex-wrap: nowrap;
  font-size: $font-size-base;
  font-weight: 500;
  min-height: 50px;
  margin-bottom: 0;

  h5 {
    font-size: $font-size-base;
  }

  .deemphasize {
    font-size: $font-size-sm;
  }
}

.navbar-form {
  .input-group {
    width: 245px;
    display: flex;

    .form-control {
      order: 2;
      font-size: $font-size-base;
      padding: 0.6rem 0.85rem 0.6rem 0;
      border-top-right-radius: $border-radius !important;
      border-bottom-right-radius: $border-radius !important;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    .input-group-text {
      order: 1;
      color: $navbar-dashboard-link-color;
      padding: 0.6rem 0.85rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: $border-radius;
      border-top-left-radius: $border-radius;
    }
  }
}

.container {
  position: static;
}

.navbar-nav {
  .nav-item {

    .dropdown-menu {
      position: absolute;
      margin-top: 0;
    }
  }
}


//
// Breadcrumbs
// --------------------------------------------------
.breadcrumb {
  padding-left: 0;
  padding-right: 0;
  color: $gray-600;
  margin-bottom: $spacer*1.5;

  >.active {
    color: $text-color;
    font-weight: $font-weight-semi-bold;
  }
}

//-----------------------------------------------------
// override a font-weight
a {
  font-weight: $font-weight-bold;
}

//
// Buttons
// --------------------------------------------------
.btn {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-semi-bold;
  height: 2.5rem;
}

.btn:hover {
  background-color: $fda-medium-blue;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.btn:hover::after {
  opacity: 0;
}

.btn:active,
.btn:focus {
  outline: none;
  background-color: $fda-medium-blue;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

// Outline button - active dictated by EX UI FDA recommendation
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: $fda-medium-blue;
  border-color: $fda-medium-blue;
}

// Outline button - hover and focus dictated by EX UI FDA recommendation
.btn-outline-primary:not(:disabled):not(.disabled):hover,
.btn-outline-primary:not(:disabled):not(.disabled):focus{
  color: $fda-blue;
  background-color: $fda-light-blue;
  border-color: $fda-light-blue;
}

.btn-link {
  color: $fda-blue;
  -webkit-user-select: text;  /* Chrome all / Safari all */
  -moz-user-select: text;     /* Firefox all */
  -ms-user-select: text;      /* IE 10+ */
  user-select: text;  
}

// Outline button - active dictated by EX UI FDA recommendation
.btn-link:not(:disabled):not(.disabled):active,
.btn-link:not(:disabled):not(.disabled):focus,
.btn-link:not(:disabled):not(.disabled):hover,
.btn-link:not(:disabled):not(.disabled).active {
  color: $fda-blue;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link-flushl {
  padding-left: 0px;
}

.btn-link-flushr {
  padding-right: 0px;
}

// .btn-link:hover {
//   background-color: transparent;
//   color: #165c82;
// }


.btn-primary {
  min-width: 9.6875rem;
  background-color: $fda-blue;
  border-color: $fda-blue;

  &:hover {
    border-color: $fda-blue;
  }
}

.btn-secondary {
  background-color: $fda-grey;
  border-color: $fda-grey;
}

.btn-secondary:hover{
  border-color: $fda-dark-grey !important;
  background-color: $fda-dark-grey !important;
}

.btn-outline-primary {
  min-width: 9.6875rem;
  color: $fda-blue;
  border: 1px solid $fda-blue-grey;
  background: $fda-white;
}

.btn-no-minwidth{
  min-width: auto;
}

.btn,
.btn-inverse,
.btn-gray {
  &:not(.active) {
    box-shadow: none !important;
  }
}

.btn-sm {
  padding-top: .35rem;
  padding-bottom: .35rem;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-lg,
.btn-group-lg>.btn,
.btn-group-lg>.btn {
  padding: 0.75rem 1.5rem !important;
}

.btn-rounded {
  @include border-radius(6px);
}

.btn-gray {
  @include button-variant($gray-light, darken($gray-light, 5%));
}

.btn-inverse {
  @include button-variant($gray-700, darken($gray-700, 10%));
}

.btn-outline {
  @include button-variant(transparent, $white);

  &:hover,
  &:focus {
    background-color: rgba($white, .1);
  }
}

.btn-link:focus,
.btn-link:active:focus,
.btn-link.active:focus {
  outline: 0;
}

.input-group>.input-group-btn:last-child>.btn[data-toggle="dropdown"] {
  @include border-right-radius($btn-border-radius);
}


.btn-group>.btn[data-toggle="dropdown"][data-original-title][title] {
  @include border-right-radius($btn-border-radius);

  &:focus,
  &:active {
    outline: none !important;
  }
}

// Hyperlink

//
// Labels
// --------------------------------------------------
.help-block {
  margin-top: 5px;
  font-size: $small-font-size;
  color: $text-muted;
  display: block;
}


//
// Button groups
// --------------------------------------------------
.btn-toolbar {
  margin-top: calc($spacer / 2);
  margin-bottom: calc($spacer / 2);

  .btn {
    margin-left: calc($spacer / 2);
  }
}


//
// Progress bars
// --------------------------------------------------
.js-progress-animate {
  transition: width 0.6s ease;
}

.progress {
  @include box-shadow(none);
  transition: width 0.6s ease;
  height: 1.3rem;
}

.progress-bar {
  height: 100%;
  border-radius: $border-radius;
  @include box-shadow(none);
}

.progress-sm {
  height: 10px;
  margin-bottom: calc($spacer / 2);
}

.progress-xs {
  height: 5px;
  margin-bottom: calc($spacer / 2);
}


.progress-bar-gray {
  background-color: $gray-300;
}

.progress-bar-gray-light {
  background-color: $gray-600;
}

.progress-primary {
  background-color: $primary;
}


//
// List groups
// --------------------------------------------------
.list-group-lg {
  .list-group-item {
    padding: 1.25rem;
  }
}

.list-group-item {
  @include transition(background .15s ease-in-out);

  &:first-child {
    border-radius: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: $gray-100;
  }

  &,
  &:hover {
    color: $text-color;
  }

  &:not(.ui-sortable-handle) {
    border-left: none;
    border-right: none;
  }
}


//
// Tooltips
// --------------------------------------------------
.tooltip-inner {
  @include border-radius(3px);
}


//
// Alerts
// --------------------------------------------------
.alert-sm {
  padding: 10px 12px;
  font-size: $font-size-mini;

  .close {
    font-size: 18px;
  }
}


//
// Popovers
// --------------------------------------------------
.popover {
  border: none;
  @include box-shadow(none);
}

.popover-content {
  padding: 20px;
}


//
// Modals
// --------------------------------------------------
.modal-open {

  &,
  &.nav-collapsed,
  &.nav-static {
    .content-wrap {
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      position: static;
    }

    .sidebar {
      z-index: -1;
    }
  }
}

.modal-footer-modified{
  display: block !important;
  align-items: normal !important;
  justify-content: normal !important;
}

.pac-container {
  z-index: 3000;
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}

.modal-header .close {
  opacity: 1 !important;
}
//
// Pagination
// --------------------------------------------------
.page-item {

  .page-link,
  >span {
    border-radius: $border-radius;
    margin: 0 2px;
  }

  .page-link {
    color: $text-color;
    border: none;
  }

  &.active {
    .page-link {
      background: $gray-200;
      color: $gray-800;
    }
  }
}


//
// Jumbotron
// --------------------------------------------------
.jumbotron {
  @include media-breakpoint-up(md) {
    padding-left: $jumbotron-padding;
    padding-right: $jumbotron-padding;
  }
}


// Navbar form
//
// --------------------------------------------------
.navbar-form {
  padding-left: $spacer*2;

  .form-group {
    margin-bottom: 0;
  }
}


//
// Navbar Dasboard
// --------------------------------------------------
.navbar-dashboard {
  background-color: $navbar-dashboard-bg;
  border-color: $navbar-dashboard-border;

  .navbar-brand {
    color: $navbar-dashboard-brand-color;
    font-size: $font-size-lg;

    &:hover,
    &:focus {
      color: $navbar-dashboard-brand-hover-color;
      background-color: $navbar-dashboard-brand-hover-bg;
    }
  }

  .navbar-text {
    color: $navbar-dashboard-color;
  }

  .navbar-nav>.nav-item>.nav-link {
    padding: $nav-link-padding-y $nav-link-padding-x;
  }

  .navbar-nav {
    >li>a {
      color: $navbar-dashboard-link-color;

      &:hover,
      &:focus {
        color: $navbar-dashboard-link-hover-color;
        background-color: $navbar-dashboard-link-hover-bg;
      }
    }

    >.active>a {

      &,
      &:hover,
      &:focus {
        color: $navbar-dashboard-link-active-color;
        background-color: $navbar-dashboard-link-active-bg;
      }
    }

    >.disabled>a {

      &,
      &:hover,
      &:focus {
        color: $navbar-dashboard-link-disabled-color;
        background-color: $navbar-dashboard-link-disabled-bg;
      }
    }
  }

  .navbar-nav {

    // Remove background color from open dropdown
    >.open>a {

      &,
      &:hover,
      &:focus {
        background-color: $navbar-dashboard-link-active-bg;
        color: $navbar-dashboard-link-active-color;
      }
    }

    @media (max-width: map_get($grid-breakpoints, md) - 1px) {

      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        >li>a {
          color: $navbar-dashboard-link-color;

          &:hover,
          &:focus {
            color: $navbar-dashboard-link-hover-color;
            background-color: $navbar-dashboard-link-hover-bg;
          }
        }

        >.active>a {

          &,
          &:hover,
          &:focus {
            color: $navbar-dashboard-link-active-color;
            background-color: $navbar-dashboard-link-active-bg;
          }
        }

        >.disabled>a {

          &,
          &:hover,
          &:focus {
            color: $navbar-dashboard-link-disabled-color;
            background-color: $navbar-dashboard-link-disabled-bg;
          }
        }
      }
    }
  }

  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .btn-link {
    color: $navbar-dashboard-link-color;

    &:hover,
    &:focus {
      color: $navbar-dashboard-link-hover-color;
      outline: none;
      text-decoration: none;
    }

    &[disabled],
    fieldset[disabled] & {

      &:hover,
      &:focus {
        color: $navbar-dashboard-link-disabled-color;
      }
    }
  }
}


@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
    color: $text-color !important;
  }

  a#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%);
    }
  }
}


//
// Code
// -----------------
pre {
  padding: 6.5px;
  margin: 0 0 calc($spacer / 2);
  line-height: $spacer;
  word-break: break-all;
  word-wrap: break-word;
  background-color: $pre-bg;
  border-radius: $border-radius;

  code {
    background: transparent;
  }
}

code {
  background: $danger-light;
}


//
// Badges
// --------------------------------------------------
.label,
.badge {
  display: inline-block;
  padding: 2px 4px;
  line-height: 14px; // ensure proper line-height if floated
  text-shadow: none;
  font-weight: $font-weight-bold;
  font-size: 11px;
}

.badge-pill {
  padding: 2px 6px;
}

.badge-default {
  @include badge-variant($default);
  color: $text-color;
}

.badge-primary {
  @include badge-variant($primary);
}

.badge-success {
  @include badge-variant($success);

  color: $white;

  &[href] {
    @include hover-focus {
      color: $white;
    }
  }
}

.badge-info {
  @include badge-variant($info);
}

.badge-warning {
  @include badge-variant($warning);

  color: $white;

  &[href] {
    @include hover-focus {
      color: $white;
    }
  }
}

.badge-danger {
  @include badge-variant($danger);
}

.badge-important {
  @include badge-variant($danger);
}

.badge-inverse {
  @include badge-variant($gray-400);
}

input[type=text],
input[type=password],
textarea {
  transition: all 0.30s ease-in-out;
  outline: none;
  border: 1px solid #DDDDDD;
}

.select2-container .select2-selection--single{
  border: 1px solid #DDDDDD !important;
}

input[type=text]:focus,
input[type=password]:focus,
textarea:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.pwd-control,
.inp-clear {
  transition: all 0.30s ease-in-out !important;
  outline: none !important;
  border-left: 1px solid #DDDDDD !important;
  border-top: 1px solid #DDDDDD !important;
  border-bottom: 1px solid #DDDDDD !important;
  border-right: none !important;
  & + .input-group-append {
    border-right: 1px solid #DDDDDD !important;
    border-top: 1px solid #DDDDDD !important;
    border-bottom: 1px solid #DDDDDD !important;
    border-left: none !important;
    @include border-right-radius($btn-border-radius);
  }
}

.pwd-control:focus,
.inp-clear:focus {
  box-shadow: none !important;
  border-left: 1px solid rgba(81, 203, 238, 1) !important;
  border-top: 1px solid rgba(81, 203, 238, 1) !important;
  border-bottom: 1px solid rgba(81, 203, 238, 1) !important;
  border-right: none !important;
  & + .input-group-append {
    border-right: 1px solid rgba(81, 203, 238, 1) !important;
    border-top: 1px solid rgba(81, 203, 238, 1) !important;
    border-bottom: 1px solid rgba(81, 203, 238, 1) !important;
    border-left: none !important;
    @include border-right-radius($btn-border-radius);
  }
}

//
// added by RB
.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
  height: auto;
}

.form-control-lg {
  font-size: 1.5rem;
  height: calc(3.30rem + 2px);
}


.nav-tabs {
  border-bottom: 3px solid $fda-blue-grey !important;
  background-color: $fda-white !important;
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

.nav-tabs > .nav-item > .nav-link {
  color: $fda-medium-blue !important;
}

.nav-tabs > .nav-item .nav-link.active, .nav-tabs > .nav-item .nav-link.active:hover, .nav-tabs > .nav-item .nav-link.active:focus {
  box-shadow: none !important;
  border-bottom: 3px solid $fda-black !important;
  color: $fda-black !important;
}

// Following 3 items have been added to address TRLMNG-1921 bug report
// :invalid {
//   box-shadow: none !important;
// }

// :-moz-submit-invalid {
//   box-shadow: none !important;
// }

// :-moz-ui-invalid {
//   box-shadow:none !important;
// }
//
